<template>
  <div class="family-container">
    <el-input
      class="search-input"
      placeholder="Family_id/User_id/Phone/Email/..."
      prefix-icon="el-icon-search"
      @keyup.enter.native="searchClick"
      v-model="searchValue">
      <el-button slot="append" type="primary" @click="searchClick">Search</el-button>
    </el-input>
    <div class="flexview">
      <div class="family-msg" style="position:absolute;">
        <img class="photo" :src="groupList.photo" alt="Family Photo" @click="previewTap(groupList.photo)">
        <h4>{{groupList.name}}</h4>
        <div>Family_id:<br/>{{groupList.group_id}}</div>
        <div>Create time:<br/>{{groupList.create_time}}</div>
        <div>Family size: {{groupList.size}}</div>
        <div>VIP: {{groupList.vip ? 'Yes' : 'No'}}</div>
        <div>Delete time: {{groupList.delete_time}}</div>
      </div>
      <div class="family-members" style="margin-left: 240px;">
        <h4>Family Members ( {{groupList.size}} )</h4>
        <el-button class="btn-export" type="text" icon="el-icon-download" @click="exportClick1">Export</el-button>
        <div class="f-table">
          <div class="f-head">
            <div class="f-td" v-for="(item,index) in originTitle" :key="index">{{item}}</div>
          </div>
          <div class="f-tbody" v-for="(item,index) in groupList.group_user_list" :key="index">
            <div @click="openDetails(item.user_id)" style="text-decoration:underline;">{{item.user_id}}</div>
            <div>{{item.group_user_id}}</div>
            <div style="font-size:15px;">{{item.register_time}}</div>
            <div style="height:72px">{{item.location}}</div>
            <div style="height:72px">
              <img v-if="item.photo!='Not set'&&item.photo!=''" class="photo" :src="item.photo" @click="previewTap(item.photo)"/>
              <div v-else style="border:0;">Not set</div>
            </div>
            <div>{{item.full_name}}</div>
            <div>{{item.nick_name}}</div>
            <div>{{item.phone}}</div>
            <div>{{item.email}}</div>
            <div style="font-size:15px;">{{item.birth}} ({{item.age}})</div>
            <div style="font-size:15px;">{{item.join_time}}</div>
            <div>{{item.role}}</div>
            <div><span :style="{background:`linear-gradient(to right, ${item.color[0]}, ${item.color.length > 1 ? item.color[1] : item.color[0]})`}" class="color"></span></div>
            <div>{{item.task_num}}</div>
            <div>{{item.view_type}}</div>
            <div>{{item.last_active_time}}</div>
            <div>{{item.push_type}}</div>
            <div>{{item.push_time}}</div>
            <div>{{item.status}}</div>
            <div>{{item.device}}</div>
            <div>{{item.device_version}}</div>
            <div>{{item.app_version}}</div>
            <div>{{item.source}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tasklist">
      <div class="head">
        <h4>Event List</h4>
        <el-button class="btn-export" type="text" icon="el-icon-download" @click="exportClick2">Export</el-button>
      </div>
      <div>
        <el-button slot="append" class="btn-ranges" @click="eventRangeClick">{{eventRangeText}}<i class="el-icon-caret-bottom"></i></el-button>
        <dataFilter
          v-for="(item,index) in eventFilterText"
          :key="index"
          :item-data="item"
          :item-index="index"
          open-type="edit"
          :keyList="familyFilter"
          @delete="eventListFilterDel"
          @apply="apply1"
        >
        </dataFilter>
        <dataFilter :item-data="eventItem" :keyList="familyFilter" open-type="add" @apply="apply1"></dataFilter>
      </div>
      <div style="position:relative;">
        <datePicker :visable.sync="eventPickerVisible" @applydate="applyDate"></datePicker>
      </div>
      <el-table
        style="width: 100%;height:auto;"
        max-height="800"
        :header-cell-style="{ background: '#F5F5F5' }"
        :row-class-name="eventRowClassName"
        :data="taskList"
        :default-sort = "{prop: 'start_time', order: 'ascending'}"
        @sort-change="changeSort1"
        >
        <el-table-column
          prop="task_id"
          label="Event_id"
          width="160">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="Create_Time"
          sortable="custom"
          width="180">
        </el-table-column>
        <el-table-column
          prop="timezone"
          label="Time_Zone"
          width="180">
        </el-table-column>
        <el-table-column
          prop="creator"
          label="Creator"
          width="120">
        </el-table-column>
        <el-table-column
          prop="user_id"
          label="User_ID"
          width="120">
        </el-table-column>
        <el-table-column
          prop="name"
          label="Event_Title"
          width="180">
        </el-table-column>
        <el-table-column
          prop="start_time"
          label="Event_Start"
          sortable="custom"
          width="180">
        </el-table-column>
        <el-table-column
          prop="end_time"
          label="Event_End"
          sortable="custom"
          width="180">
        </el-table-column>
        <el-table-column
          prop="uids"
          label="Participates"
          width="180">
        </el-table-column>
        <el-table-column
          prop="visible_uids"
          label="Visible by"
          width="180">
        </el-table-column>
        <el-table-column
          prop="remind"
          label="Reminder"
          width="120">
        </el-table-column>
        <el-table-column
          prop="repeat"
          label="Recurring"
          width="120">
        </el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          width="80">
        </el-table-column>
        <el-table-column
          prop="note"
          label="Note"
          width="180">
        </el-table-column>
      </el-table>
      <pagination :onlineList="tableData" @changepage="changePage" @changesize="changeSize"></pagination>
    </div>
    <div class="viplist">
      <h4>VIP Record</h4>
      <el-table
        style="width: 100%;height:auto;"
        max-height="400"
        :header-cell-style="{ background: '#F5F5F5' }"
        :data="groupList.vip_list"
        >
        <el-table-column
          prop="num"
          label="Num"
          width="120">
        </el-table-column>
        <el-table-column
          prop="start_time"
          label="Start Time">
        </el-table-column>
        <el-table-column
          prop="end_time"
          label="End Time">
        </el-table-column>
        <el-table-column
          prop="subscribe_time"
          label="Pay time">
        </el-table-column>
        <el-table-column
          prop="subscriber"
          label="Subscriber">
        </el-table-column>
        <el-table-column
          prop="plan"
          label="Plan">
        </el-table-column>
        <el-table-column
          prop="from"
          label="From">
        </el-table-column>
      </el-table>
    </div>
    <div class="preview" v-if="showPreview" @click="previewTap">
      <img class="img" :src="preview"/>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/cubbily/DatePicker';
import DataFilter from '@/components/cubbily/DataFilter';
import Pagination from '@/components/cubbily/Pagination';
import {searchAll, groupList, groupTaskList, exportReq} from '@/api/cubbily.js';
import moment from 'moment';

export default {
  name: 'FamilyInfo',
  data () {
    return {
      searchValue: '',
      groupId: '',
      groupList: {},
      originTitle: ['User_id', 'Group_User_id', 'Register Time', 'Location', 'Profile', 'Full Name', 'Nickname', 'Phone', 'Email', 'DoB(Age)', 'Join Family Time', 'Role', 'Color', 'Num of Event', 'view_type', 'last_active_time', 'push_type', 'push_time', 'status', 'device', 'device_version', 'app_version', 'source'],
      transData: [],
      notifyType: ['', 'mins', 'hours', 'days'],
      notifyType2: ['No', 'n minutes before', 'n hours before', 'n days before'],
      taskList: [],
      eventRangeText: 'Last 90 days',
      eventFilterText: [],
      eventStartTime: '',
      eventEndTime: '',
      eventItem: {
        keys: '1'
      },
      tableData: {
        pageSize: 30,
        pageNum: 1,
        total: 10,
        order: '',
        sort: ''
      },
      viplist: [],
      eventPickerVisible: false,
      taskVisible: false,
      keyFilter: '',
      dataParams: {},
      filterParams: {},
      roles: ['Mom', 'Dad', 'Son/Daughter/Child', 'Grandparent', 'Partner/Spouse', 'Friend', 'Other'],
      familyFilter: [
        {
          value: 'task_id',
          label: 'Event_id'
        },
        {
          value: 'create_start',
          label: 'Event_Start'
        },
        {
          value: 'create_end',
          label: 'Event_End'
        },
        {
          value: 'reminder_1',
          label: 'Reminder1'
        },
        {
          value: 'reminder_2',
          label: 'Reminder2'
        },
        {
          value: 'recurring',
          label: 'Recurring'
        },
        {
          value: 'status',
          label: 'Status'
        },
        {
          value: 'note',
          label: 'Note'
        }
      ],
      operatorList: [
        {'=': 1 }, {'≠': 2 }, {'>': 3 }, {'<': 4 }, {'is one of': 5 }, {'exists': 6 }
      ],
      recurringList: ['No', 'Every Day', 'Every Week', 'Every Month', 'Every Year', 'Every other Day', 'Every other Week', 'Every other Month', 'Every other Year'],
      showPreview: false,
      preview: ''
    };
  },
  components: {
    datePicker: DatePicker,
    dataFilter: DataFilter,
    pagination: Pagination
  },
  methods: {
    eventRowClassName ({row, rowIndex}) {
      if (row.status == 'Deleted') {
        return 'deleted-row';
      }
      return '';
    },
    // 拿到子组件回传的参数
    apply1 (data) {
      if (data.isAddFilterKey) {
        this.eventFilterText.push(data);
        console.log('add', this.eventFilterText);
      } else {
        this.eventFilterText.forEach((item) => {
          if (item.key == data.key) {
            item.operator = data.operator;
            item.value = data.value;
          }
        });
        console.log('reset', this.eventFilterText);
      }

      let op = '', val = data.value;
      this.operatorList.forEach((item) => {
        if (Object.keys(item) == data.operator) {
          op = item[data.operator];
        }
      });
      if (data.key == 'recurring' && this.recurringList.includes(val)) {val = this.recurringList.indexOf(val) + 1;}
      if (data.key == 'status') {val = val == 'Deleted' ? 2 : 1;}

      let req = {};
      if (data.keyText == 'Reminder1' || data.keyText == 'Reminder2') {
        let notify_type = ['%', '%'], notify_before = ['%', '%'];
        if (this.filterParams.notify_type) {
          notify_type = this.filterParams.notify_type.split(' ')[1];
          notify_before = this.filterParams.notify_before.split(' ')[1];
          if (notify_type.indexOf(',') != -1) {
            notify_type = notify_type.split(',');
            notify_before = notify_before.split(',');
          } else {
            notify_type = notify_type.split('');
            notify_before = notify_before.split('');
          }
        }
        let isOne = data.keyText == 'Reminder1';
        let typeIndex = this.notifyType2.indexOf(data.value);
        if (isOne) {
          notify_type[0] = typeIndex > -1 ? typeIndex : 1;
          notify_before[0] = data.number || 0;
          if (notify_type[1] == '%' && notify_before[1] == '%') {
            notify_type = notify_type.toString().replace(/,/g, '');
            notify_before = notify_before.toString().replace(/,/g, '');
          }
        } else {
          notify_type[1] = typeIndex > -1 ? typeIndex : 1;
          notify_before[1] = data.number || 0;
          notify_type = notify_type.toString();
          notify_before = notify_before.toString();
        }
        let type, before;
        if (data.operator == 'exists') {
          type = `${op} ${isOne ? 1 : 2}`;
          before = `${op} ${isOne ? 1 : 2}`;
        } else if (data.operator != 'exists') {
          let isNo = data.operator == '=' && data.val != 'No';
          type = isNo ? `7 ${isOne ? 1 : 2}` : `${op} ${notify_type}`;
          before = isNo ? `7 ${isOne ? 1 : 2}` : `${op} ${notify_before}`;
        }
        req = {
          'notify_type': type,
          'notify_before': before
        };
      } else {
        req = {
          [data.key]: data.operator != 'exists' ? `${op} ${val}` : `${op}`
        };
      }
      this.tableData.pageNum = 1;
      let reqData = Object.assign(this.filterParams, req);
      this.getTaskList(reqData);

      // let reqData = Object.assign(this.filterParams, {
      //   [data.key]: data.operator != 'exists' ? `${op} ${val}` : `${op}`
      // });
      // this.getTaskList(reqData);
    },

    // add filter 删除某个条件
    eventListFilterDel (i) {
      let key = this.eventFilterText[i].key;
      let obj = this.filterParams;
      let notReminder = true;
      this.eventFilterText.splice(i, 1);
      if (key == 'reminder_1' || key == 'reminder_2') {
        let op1 = obj.notify_type.split(' ')[0];
        let op2 = obj.notify_before.split(' ')[0];
        let notify_type = obj.notify_type.split(' ')[1];
        let notify_before = obj.notify_before.split(' ')[1];
        if (notify_type.indexOf(',') != -1) {
          notify_type = notify_type.split(',');
          notify_before = notify_before.split(',');
        } else {
          notify_type = notify_type.split('');
          notify_before = notify_before.split('');
        }
        if (key == 'reminder_1') {
          notify_type[0] = '%';
          notify_before[0] = '%';
          notify_type = notify_type.toString();
          notify_before = notify_before.toString();
        } else {
          notify_type[1] = '%';
          notify_before[1] = '%';
          notify_type = notify_type.toString().replace(/,/g, '');
          notify_before = notify_before.toString().replace(/,/g, '');
        }
        if (this.eventFilterText.length) {
          this.eventFilterText.forEach((item) => {
            if (item.key == 'reminder_1' || item.key == 'reminder_2') {
              notReminder = false;
            }
          });
        }
        if (!this.eventFilterText.length || notReminder) {
          delete obj['notify_type'];
          delete obj['notify_before'];
        } else {
          this.filterParams.notify_type = `${op1} ${notify_type}`;
          this.filterParams.notify_before = `${op2} ${notify_before}`;
        }
      } else {
        delete (obj[key]);
      }
      this.tableData.pageNum = 1;
      this.getTaskList(this.filterParams);
    },

    getGroupList () {
      groupList({
        group_id: this.groupId
      })
        .then((res) => {
          if (res.result != 1) {
            this.$message.error(res.error_msg);
            return;
          }
          this.groupList = res.data;
          // this.groupList.create_time = moment(this.groupList.create_time).format('YYYY-MM-DD HH:mm:ss');
          let list = this.groupList.group_user_list;
          list.forEach((item) => {
            for (let key in item) {
              if (key != 'photo' && key != 'task_num' && item[key] == '') {item[key] = 'Not set';}
            }
            item.age = (item.age < 0 || item.age > 200) ?  'Not Set' : item.age;
            item.role = item.role > 0 ? this.roles[item.role - 1] : 'Other';
            const map = {
              1: 'Day',
              2: 'Week',
              3: 'Month',
              4: 'Task'
            };
            item.view_type = map[item.view_type];
            item.push_type = item.push_type == 1 ? 'ON' : 'OFF';
            item.status = item.status == 1 ? 'in family' : `left ${item.leave_time}`;
            // item.register_time = moment(item.register_time).format('YYYY-MM-DD HH:mm:ss');
            // item.join_time = moment(item.join_time).format('YYYY-MM-DD HH:mm:ss');
          });
          this.groupList.group_user_list = list;
          let viplist = this.groupList.vip_list;
          for (let i in viplist) {
            viplist[i].num = parseInt(i) + 1;
          }
          this.groupList.vip_list = viplist;
        })
        .catch((error) => {
          console.log('getGroupList：', error);
          this.$message.error(error.error_msg);
        });
    },
    getTaskList (params) {
      let data = Object.assign({
        group_id: this.groupId,
        start_time: this.eventStartTime,
        end_time: this.eventEndTime,
        order: this.tableData.order,
        sort: this.tableData.sort,
        page: this.tableData.pageNum,
        limit: this.tableData.pageSize
      }, params);
      console.log('日程列表data:', data);
      groupTaskList(data)
        .then((res) => {
          if (res.result != 1) {
            this.$message.error(res.error_msg);
            return;
          }
          let list = res.data.task_list;
          list.forEach((item) => {
            item.uids = item.uids.split('|').join(',') || '-';
            item.visible_uids = item.visible_uids.split('|').join(',') || '-';
            // item.notify_type = item.notify_type.split(',');
            // item.notify_before = item.notify_before.split(',');
            // let text = [];
            // if (item.notify_type.length) {
            //   for (let i in item.notify_type) {
            //     let str = '';
            //     if (item.notify_before[i] == 0) {
            //       str = item.notify_type[i] == 0 ? 'No' : 'At the time of event';
            //     } else {
            //       let after = this.notifyType[item.notify_type[i]];
            //       str = `${item.notify_before[i]} ${after} before`;
            //     }
            //     text.push(str);
            //   }
            //   item.reminder1 = text[0] || 'No';
            //   item.reminder2 = text[1] || 'No';
            // } else {
            //   item.reminder1 = 'No';
            //   item.reminder2 = 'No';
            // }
            // let i = this.recurringList.indexOf(item.repeat);
            // item.repeat = i > -1 ? this.recurringList[i] : '-';

            item.status = item.status != 1 ? 'Deleted' : '-';
            item.note = item.note ? item.note : '-';
          });
          this.taskList = list;
          this.tableData.total = res.data.total;
          this.exportReqData2 = Object.assign(data, {limit: res.data.total});
        })
        .catch((error) => {
          console.log('getTaskList：', error);
          this.$message.error(error.error_msg);
        });
    },

    // time ranger点击
    eventRangeClick () {
      this.eventPickerVisible = !this.eventPickerVisible;
      this.dateFilter = 'eventPicker';
    },
    // add filter点击
    taskListFilter (item) {
      if (item) {
        let itemData = item.split('  ');
        this.eventItem = {
          keys: itemData[0],
          operator: itemData[1],
          values: itemData[2]
        };
      } else {
        this.eventItem = {
          keys: '',
          operator: '',
          values: ''
        };
      }
      this.taskVisible = !this.taskVisible;
      this.keyFilter = 'familyFilter';
      if (this.taskVisible) {
        this.$refs['taskFilter'].openModal(this.eventItem);
      }
    },
    // apply点击
    applyFilter (data) {
      console.log('父组件applyFilter：', data);
      this.eventFilterText = data.filterValue;
      this.getTaskList(Object.assign(this.dataParams, data.params));
      this.filterParams = data.params;
    },
    applyDate (data) {
      console.log('父组件applyDate：', data);
      if (data.text) {this.eventRangeText = data.text;}
      this.getTaskList(Object.assign(this.filterParams, data.params));
      this.dataParams = data.params;
    },

    exportClick1 () {
      exportReq({
        url: '/v2/group/group_user_export',
        fileName: 'familyMembers',
        data: {
          group_id: this.groupId
        }
      });
    },
    exportClick2 () {
      exportReq({
        url: '/v2/group/task_export',
        fileName: 'familyEvent',
        data: {
          ...this.exportReqData2
        }
      });
    },
    changeSize (size) {
      if (size) {
        this.tableData.pageSize = size;
        this.tableData.pageNum = 1;
        this.getTaskList(this.filterParams);
      }
    },
    changePage (current) {
      if (current) {
        this.tableData.pageNum = current;
        this.getTaskList(this.filterParams);
      }
    },
    openDetails (row) {
      searchAll({
        platform_id: row
      })
        .then((res) => {
          if (res.result != 1) {
            this.$message.error('Not found');
            return;
          }
          let data = res.data;
          this.$router.push({
            name: 'UserInfo',
            params: { searchId: data.id }
          });
        })
        .catch((error) => {
          console.log('searchAll：', error);
          this.$message.error(error.error_msg);
        });
    },

    previewTap (img) {
      this.showPreview = !this.showPreview;
      if (this.showPreview) {
        this.preview = img;
      } else {
        this.preview = '';
      }
    },
    // 排序的方法
    changeSort1 (data) {
      console.log(data); // column: {…} order: "ascending" prop: "date"
      // 支持参数create_time日程创建时间
      let prop = data.prop;
      let sort = data.order == 'ascending' ? 1 : 0;
      this.tableData.pageNum = 1;
      this.tableData.order = prop;
      this.tableData.sort = sort;
      this.getTaskList({});
    },

    searchClick () {
      const searchValue = this.searchValue.replace(/\s+/g, '');
      searchAll({
        platform_id: searchValue
      })
        .then((res) => {
          if (res.result != 1) {
            this.$message.error('Not found');
            return;
          }
          let data = res.data;
          if (data.type == 1) {
            this.groupId = data.id;
            this.getFamilyInfo();
          } else {
            this.$router.push({
              name: 'UserInfo',
              params: { searchId: data.id }
            });
          }
        })
        .catch((error) => {
          console.log('searchAll：', error);
          this.$message.error(error.error_msg);
        });
    },

    getFamilyInfo () {
      window.sessionStorage.setItem('cubbilyFamilyId', this.groupId);
      let date = new Date();
      let timenow = new Date().getTime();
      let timeago = date.setTime(date.getTime() - 3600 * 1000 * 24 * 90);
      this.eventStartTime = timeago;
      this.eventEndTime = timenow;
      this.eventRangeText = 'Last 90 days';
      this.filterParams = {};

      this.getGroupList();
      this.getTaskList();
    }
  },

  beforeCreate () {},
  created () {
    this.groupId = this.$route.params.searchId || window.sessionStorage.getItem('cubbilyFamilyId');
    this.getFamilyInfo();
  },
  beforeMount () {},
  mounted () {},
  beforeDestroy () {
  },
  destroyed () {}
};
</script>
<style lang="scss">
.family-container {
  width: calc(100% - 24px);
  // height: calc(100vh - 50px - 12px - 24px);
  padding: 22px;
  box-sizing: border-box;
  margin: 12px;
  background: #fff;
  position: relative;
  .search-input{
    width: 480px;
  }
  .flexview{
    // display: flex;
    // justify-content: space-between;
    margin-bottom: 20px;
    .family-msg{
      width: 220px;
      height: 420px;
      padding: 24px 12px;
      background: #edf0f4;
      border-radius: 5px;
      margin-right: 22px;
      float: left;
      .photo{
        width: 100%;
        height: 120px;
        line-height: 120px;
        background: #dcdcdc;
        display: block;
        text-align: center;
      }
      h4{
        margin: 14px 0;
      }
      div{
        margin-bottom: 15px;
      }
    }
    .family-members{
      display: flex;
      flex-direction: column;
      h4{
        margin-bottom: 10px;
        float: left;
      }
      .btn-export{
        padding: 0;
        position: absolute;
        right: 24px;
      }
    }
  }
  .tasklist,
  .viplist{
    margin-bottom: 30px;
    h4{
      margin: 50px 0 10px;
      float: left;
    }
    .btn-export{
      margin: 50px 0 10px;
      padding: 0;
      position: absolute;
      right: 24px;
    }
  }
}
.el-table .deleted-row{
  background: #E6A23C;
}
.avatar{
  width: 50px;
  height: 50px;
  border-radius:50%;
  margin:0 auto;
  display: block;
}
.color{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin:0 auto;
  display: block;
}
.el-table thead {
  color: #000;
}
.el-table th, .el-table td{
  padding: 6px 0;
}
.pagination{
  float: right;
}
.family-members .el-table__header-wrapper{
  display: none;
}
.family-members .el-table{
  width: calc(100% - 232px);
  overflow: scroll;
}
.f-table{
  display:flex;
  border: 1px solid #EBEEF5;
  border-bottom: 0;
  width: calc(100%);
  overflow-x: scroll;
}
.f-head{
  // width: 100%;
  font-weight: 500;
  color: #000;
  background: rgb(245, 245, 245);
  .f-td{
    width: 140px;
  }
  .f-td:nth-child(2),
  .f-td:nth-child(10){
    font-size:15px;
  }
  .f-td:nth-child(4),
  .f-td:nth-child(5){
    height:72px
  }
}
.f-td,
.f-tbody div{
  min-width: 180px;
  height: 40px;
  line-height: 28px;
  padding: 6px;
  border-bottom: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
  .photo{
    width: 50px;
    height: 50px;
    margin: 5px auto 0;
    display: block;
    border-radius: 50%;
  }
  .color{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}
.head{
  display: flex;
  margin: 50px 0 10px;
}
.btn-ranges{
  margin-bottom: 10px;
}
.btn-filter{
  text-decoration:underline
}
.preview{
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  .img{
    transform: scale(.5);
  }
}
</style>
